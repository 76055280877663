import { useSetAtom } from "jotai";
import React, { useCallback } from "react";
import { keyCodes } from "ui/lib/constants";
import { createContainer } from "unstated-next";
import { v4 } from "uuid";
import { wasMultiSelectKeyUsed } from "../chat/input/helpers";
import { streamingTextAtom, useChatBotAIProps } from "./ChatBotAIPropsProvider";
import { Example, useChatBotUIConfig } from "./ChatBotUIProvider";
import { simulateTextStreaming } from "./helpers";
function useContainer() {
  const chatProps = useChatBotAIProps();
  const {
    onSubmit,
    onAction,
    scrollToBottom,
    setExamples,
    onExampleClicked
  } = useChatBotUIConfig();

  // @ts-ignore
  const setStreamingText = useSetAtom(streamingTextAtom);
  const handleSubmit = useCallback(async (e: React.FormEvent<HTMLFormElement>) => {
    if (chatProps.isLoading || !chatProps.input.trim()) return;
    chatProps.handleSubmit(e);
    if (typeof onSubmit === "function") {
      onSubmit(chatProps.input);
      scrollToBottom();
    }
  }, [chatProps, onSubmit]);
  const handleSubmitExample = useCallback(async (example: Example, index: number) => {
    if (chatProps.isLoading) return;
    const valueToSend = example.user;
    if (!valueToSend) return;
    if (typeof onExampleClicked !== "function") {
      onSubmit?.(valueToSend); // maybe move this up?
      return chatProps.append({
        content: valueToSend,
        role: "user"
      });
    }
    scrollToBottom();
    const {
      data
    } = await onExampleClicked(example);
    chatProps.setMessages([...chatProps.messages, {
      content: valueToSend,
      role: "user",
      id: v4()
    }]);
    setExamples((current: Example[]) => {
      const result = current.filter((_, i) => i !== index);
      return result;
    });
    await simulateTextStreaming(data, setStreamingText, 20);
    chatProps.setMessages([...chatProps.messages, {
      content: valueToSend,
      role: "user",
      id: v4()
    }, {
      content: data,
      role: "assistant",
      id: v4()
    }]);
    setStreamingText("");
  }, [chatProps, onSubmit]);
  const handleOnKeyDown = useCallback(async e => {
    if (e.keyCode === keyCodes.enter && !wasMultiSelectKeyUsed(e)) {
      e.preventDefault();
      await handleSubmit(e);
    }
  }, [handleSubmit]);
  return {
    onAction,
    handleSubmit,
    handleSubmitExample,
    handleOnKeyDown
  };
}
export const {
  Provider: ChatBotActionsProvider,
  useContainer: useChatBotActions
} = createContainer(useContainer);