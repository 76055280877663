"use client";

import { ArrowLeftIcon, ArrowRightIcon } from "@radix-ui/react-icons";
import { AnimatePresence, motion } from "motion/react";
import Link from "next/link";
import { Team } from "queries/teams";
import { Ref, useRef, useState } from "react";
import useInView from "react-cool-inview";
import { SnapItem, SnapList, useScroll, useVisibleElements } from "react-snaplist-carousel";
import { ContentDots } from "ui/components/content-dots";
import { useIsDesktop } from "ui/hooks/use-media-query";
import { captureEvent } from "ui/lib/captureEvent";
import { useIsMobile } from "ui/lib/use-breakpoint";
import { cn } from "ui/lib/utils";
const LoadingView = () => {
  return <div className="w-full h-screen text-foreground bg-background center" data-sentry-component="LoadingView" data-sentry-source-file="team-card.tsx">
      <img className="animate-bounce" src="/assets/logo/white.svg" alt="Ladder Logo" width={632 * 0.2} height={104 * 0.2} />
    </div>;
};
const TeamItem = ({
  team,
  onClick,
  lastCardRef,
  isFirstCard,
  isLastCard,
  firstCardRef,
  visible,
  index
}) => {
  const isDesktop = useIsDesktop();
  const {
    observe,
    inView
  } = useInView({
    threshold: [0.4, 0.8]
  });
  return <div key={team.id} className="relative group" data-sentry-component="TeamItem" data-sentry-source-file="team-card.tsx">
      <Link href={`/app/team/${team.id}`} ref={el => {
      if (el) observe(el as HTMLElement);
    }} onClick={e => {
      if (!inView) e.preventDefault();
      captureEvent({
        category: "Team Selection",
        action: "Team Details Clicked",
        team_id: team.id
      });
      onClick(e);
    }} className={cn("group overflow-hidden bg-secondary relative rounded flex center group", "w-[calc(100vw-32px)] max-w-lg lg:w-[24rem]", "h-[78vh] max-h-[35rem] lg:h-[35rem]", "border transition-colors", {
      "border-transparent": !inView,
      "border-transparent lg:hover:border-neutral-500": inView
    })} data-sentry-element="Link" data-sentry-source-file="team-card.tsx">
        <div ref={isLastCard ? lastCardRef : isFirstCard ? firstCardRef : null} style={{
        backgroundImage: `url(${team.posterImgixURL + "?w=600"})`
      }} className={cn("absolute inset-0 w-full pointer-events-none bg-cover bg-center transition-all scale-100", {
        "group-hover:scale-110": inView
      })} />
        <div className="absolute inset-0 w-full pointer-events-none transition-all duration-300 bg-gradient-to-t from-background/90 to-black/10 group-hover:from-background group-hover:to-transparent group-hover:bg-transparent mt-auto" />
        <div className="flex flex-col gap-4 relative justify-between p-2 mt-auto mb-8 text-center">
          <div className="text-foreground">
            <span className="">{team.coachNamesDesc}</span>
            <h3 className="text-3xl lg:text-4xl font-druk-wide text-foreground">
              {team.name}
            </h3>
            <p className="mt-1 px-4 text-accent-foreground group-hover:text-foreground">
              {team.tagline}
            </p>
          </div>
        </div>
      </Link>
    </div>;
};
export const TeamsComponent = ({
  teams
}: {
  teams: Team[];
}) => {
  const isMobile = useIsMobile();
  const snapList = useRef<any>(null);
  const [lastCardInView, setLastCardInView] = useState(false);
  const [firstCardInView, setFirstCardInView] = useState(false);
  const {
    observe: observeFirstCard
  } = useInView({
    threshold: 0.25,
    onEnter: () => setFirstCardInView(true),
    onLeave: () => setFirstCardInView(false)
  });
  const {
    observe: observeLastCard
  } = useInView({
    threshold: 0.25,
    onEnter: () => setLastCardInView(true),
    onLeave: () => setLastCardInView(false)
  });
  const visible = useVisibleElements({
    debounce: 10,
    ref: snapList
  }, elements => {
    switch (elements.length) {
      case 1:
        return elements[0];
      case 2:
      case 3:
        return elements[1];
      default:
        return elements[0];
    }
  });
  const goToSnapItem = useScroll({
    ref: snapList
  });
  const {
    observe
  } = useInView({
    threshold: 0.5
  });
  return <>
      <div className="max-w-full w-full mx-auto relative" ref={observe as unknown as Ref<any>}>
        <SnapList
      // disabled
      direction="horizontal" className="snap snap-x snap-center snap-mandatory lg:snap-proximity" ref={snapList} data-sentry-element="SnapList" data-sentry-source-file="team-card.tsx">
          {teams.map((team, index) => <SnapItem key={index} margin={{
          left: !index && !isMobile ? "0" : isMobile ? "4px" : "8px",
          right: isMobile ? "0" : "8px"
        }} snapAlign="center" className={cn({
          // "snap-center snap-always lg:first:pl-8": initialPadding,
        })}
        // className="w-11/12 md:w-1/2 lg:w-4xl"
        >
              <TeamItem onClick={() => goToSnapItem(index, {
            animationEnabled: true
          })} visible={visible === index} team={team} index={index} lastCardRef={observeLastCard} firstCardRef={observeFirstCard} isLastCard={index === teams.length - 1} isFirstCard={index === 0} />
            </SnapItem>)}
        </SnapList>
        <AnimatePresence mode="wait" data-sentry-element="AnimatePresence" data-sentry-source-file="team-card.tsx">
          {!firstCardInView && teams.length > 1 ? <motion.div initial={{
          opacity: 0
        }} animate={{
          opacity: 1
        }} exit={{
          opacity: 0
        }}>
              <button onClick={() => goToSnapItem(visible ? visible - 1 : 0, {
            animationEnabled: true
          })} className="absolute inset-y-0 left-4 z-20 hidden lg:flex center">
                <div className={cn("p-2 mr-2 border rounded-full bg-secondary lg:border-neutral-700/60", {
              "opacity-0": firstCardInView
            })}>
                  <ArrowLeftIcon className="w-5 h-5 text-foreground" />
                </div>
              </button>
              <div className="hidden lg:block absolute top-0 bottom-0 left-0 z-10 w-20 pointer-events-none bg-gradient-to-r from-background to-transparent" />
            </motion.div> : null}
        </AnimatePresence>
        <AnimatePresence mode="wait" data-sentry-element="AnimatePresence" data-sentry-source-file="team-card.tsx">
          {lastCardInView && teams.length > 1 ? <motion.div initial={{
          opacity: 0
        }} animate={{
          opacity: 1
        }} exit={{
          opacity: 0
        }}>
              <button onClick={() => goToSnapItem(visible + 2, {
            animationEnabled: true
          })} className="absolute inset-y-0 right-2 z-20 hidden lg:flex center">
                <div className={cn("p-2 mr-2 border rounded-full bg-secondary border-neutral-700/60", {
              "opacity-0": lastCardInView
            })}>
                  <ArrowRightIcon className="w-5 h-5 text-foreground" />
                </div>
              </button>
              <div className={cn("hidden lg:block absolute top-0 bottom-0 right-0 z-10 w-20 pointer-events-none bg-gradient-to-l from-background to-transparent", {
            "opacity-0": lastCardInView
          })} />
            </motion.div> : null}
        </AnimatePresence>
      </div>
      {teams.length > 3 ? <div className="container mx-auto space-x-2 mt-12 center hidden lg:flex">
          {[...Array(4)].map((_, index) => {
        // Create an array with values 0 to 3, 3 to 6, 6 to 9, or 9 to 12 depending on index
        const range = [3 * index, 3 * index + 1, 3 * index + 2];
        const isActive = range.includes(visible);
        return <motion.div key={index} layout onClick={() => goToSnapItem(range[1])} style={{
          background: isActive ? "#fff" : "#545454"
        }} animate={{
          width: isActive ? 50 : 8
        }} className={cn("z-10 h-2 cursor-pointer text-center list-none rounded-full")} />;
      })}
        </div> : null}
      {teams.length > 1 ? <div className="my-4 lg:hidden">
          <ContentDots length={teams.length} handleScrollTo={goToSnapItem} activeIndex={visible} />
        </div> : null}
    </>;
};