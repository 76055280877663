import { cva } from "class-variance-authority";
import debounce from "lodash/debounce";
import { useEffect, useState } from "react";

export const simulateTextStreaming = async (
  response: string,
  setStreamingText: any,
  t = 10
) => {
  if (typeof setStreamingText !== "function") return;
  const split = response.split(" ");

  const delay = (duration) => {
    const randomDuration = Math.floor(Math.random() * 20); // random number between 0 and 4
    return new Promise((resolve) =>
      setTimeout(resolve, duration + randomDuration)
    );
  };

  await delay(500);

  for (let i = 0; i < split.length; i++) {
    await delay(t);
    setStreamingText((prev = "") => (prev || "") + (split[i] || "") + " ");
  }
};

export const dialogContainerVariants = cva(
  "p-0 gap-0 fixed border border-neutral-700 overscroll-none rounded-t-xl",
  {
    variants: {
      variant: {
        center: "rounded-t-lg",
        bottomRight:
          "rounded-b-none h-[100vh] w-full md:max-w-lg md:max-w-[425px] md:h-[600px] md:bottom-0 md:right-4",
        bottomLeft:
          "rounded-b-none h-[100vh] w-full md:max-w-lg md:max-w-[425px] md:h-[600px] md:bottom-0 md:left-4",
      },
    },
    defaultVariants: {
      variant: "bottomRight",
    },
  }
);

export function useAdjustContentHeight() {
  const [contentHeight, setContentHeight] = useState("100%");

  useEffect(() => {
    function handleResize() {
      const windowHeight = window.innerHeight;
      const documentHeight = document.documentElement.offsetHeight;
      const isKeyboardOpen = documentHeight > windowHeight;

      if (isKeyboardOpen) {
        const visibleHeight =
          windowHeight - (window.visualViewport?.offsetTop || 0);
        setContentHeight(`${visibleHeight}px`);
      } else {
        setContentHeight("100%");
      }
    }

    // Debounce the resize event handler
    const debouncedResizeHandler = debounce(handleResize, 50);

    window.addEventListener("resize", debouncedResizeHandler);
    return () => window.removeEventListener("resize", debouncedResizeHandler);
  }, []);

  return contentHeight;
}
