import { CSSProperties, FC, ReactNode } from "react";
import { cn } from "ui/lib/utils";
interface AnimatedShinyTextProps {
  children: ReactNode;
  className?: string;
  shimmerWidth?: number;
}
const AnimatedShinyText: FC<AnimatedShinyTextProps> = ({
  children,
  className,
  shimmerWidth = 100
}) => {
  return <p style={{
    "--shimmer-width": `${shimmerWidth}px`
  } as CSSProperties} className={cn("text-neutral-400/50 ",
  // Shimmer effect
  "animate-shimmer-text bg-clip-text bg-no-repeat [background-position:0_0] [background-size:var(--shimmer-width)_100%] [transition:background-position_1s_cubic-bezier(.6,.6,0,1)_infinite]",
  // Shimmer gradient
  "bg-gradient-to-r from-transparent via-50% to-transparent via-white/80", className)} data-sentry-component="AnimatedShinyText" data-sentry-source-file="index.tsx">
      {children}
    </p>;
};
export default AnimatedShinyText;