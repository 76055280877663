import ReactLinkify from "react-linkify";
import { validateHref } from "ui/lib/helpers";

export const Linkify = ({ children }) => {
  return (
    <ReactLinkify
      componentDecorator={(decoratedHref, decoratedText, key) => (
        <a
          key={key}
          className="font-medium underline break-all text-primary"
          href={validateHref(decoratedHref)}
          target="_blank"
          rel="noreferrer"
        >
          {decoratedText}
        </a>
      )}
    >
      {children}
    </ReactLinkify>
  );
};
